<template>
  <v-list
    dense
    class="rounded-b-xl"
    v-if="results"
    >
    <v-list-item-group
      color="primary"
      three-line
      >
      <template
        v-for="(result, i) in results"
        >
        <v-divider
          v-if="!i"
          ></v-divider>
        <v-list-item
          :key="i"
          :to="{ name: 'profile', params: { slug: result.slug, search: true } }"
          >
          <v-list-item-avatar
            rounded="lg"
            size="50"
            >
            <v-img
              v-if="result.picture"
              :src="url + result.picture.thumbUrl"
              >
            </v-img>
            <v-icon
              size="40"
              v-else
              >
              {{ result.profilableType == 'User' ? 'mdi-account-circle' : 'mdi-storefront' }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="result.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="!results.length"
          >
          <v-list-item-content
            class="text-center"
            >
            <v-list-item-title>¡Parece que no hay nada que ver aquí!</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    results: {
      type: Array | Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'url'])
  }
}
</script>
